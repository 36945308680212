<template>
  <v-app>
    <v-content>
      <div class="center">
        <div class="page">
          <v-progress-circular
            indeterminate
            size="70"
            class="loading"
            color="primary"
          ></v-progress-circular>
          <h2 class="title">{{ $t("please_wait") }}</h2>
          <p class="sub-title">{{ $t("redirect_other_platform") }}</p>
        </div>
      </div>
    </v-content>
  </v-app>
</template>

<script>
import { accessRoutersVueApp } from "@/shared/enums/RedirectReactAppToVueAppEnum";
import { UserTypeEnum } from "@/shared/enums/UserType";
import ApiService from "@/services/ApiService";

export default {
  name: "AppHandler",
  data() {
    return {
      accessRoutersVueApp,
      UserTypeEnum,
      apiService: new ApiService(),
    };
  },

  created() {
    try {
      let investorFormData = {
        ...this.$route.query,
      };

      // DECODE DA PROP FORM_DATA QUE VEM COMO BASE64
      const decodedFormData = Buffer.from(
        investorFormData["form_data"],
        "base64"
      ).toString("utf-8");
      const parsedDecodedFormData = JSON.parse(decodedFormData);
      const parsedAccessVueRouterPermissions = JSON.parse(
        parsedDecodedFormData.accessVueRouterPermissions
      );

      // PROPS DEFAULT QUE PRECISAM SER PASSADAS PARA NÃO QUEBRAR A APLICAÇÃO VUE
      this.$store.commit("SET_CURRENT_TOKEN", parsedDecodedFormData.token);
      this.$store.commit("SET_EXPIRES_TOKEN", parsedDecodedFormData.expires);
      this.$store.commit("SET_SIGNED_NDA", parsedDecodedFormData.signedNda);
      this.$store.commit("SET_LANGUAGE", parsedDecodedFormData.lang);
      localStorage.setItem("reactApp", parsedDecodedFormData.reactApp);

      // MOSTRA O APPBAR + DRAWER SE O ACESSO FOR PERMITIDO A APLICAÇÃO INTEIRA.
      localStorage.setItem(
        "accessVueNavigation",
        parsedAccessVueRouterPermissions.access ===
          this.accessRoutersVueApp.ALL_PLATFORM &&
          parsedDecodedFormData.showVueDrawer === "true"
          ? "true"
          : "false"
      );

      // PERMISSÕES DE ROTA PARA O USUÁRIO NA APLICAÇÃO VUE
      localStorage.setItem(
        "accessVueRouterPermissions",
        parsedDecodedFormData.accessVueRouterPermissions
      );

      // SALVANDO A ROTA QUE O USUÁRIO VAI ACESSAR VINDO DO REACT
      if (
        parsedAccessVueRouterPermissions.access ===
        this.accessRoutersVueApp.ONLY_PAGE
      ) {
        localStorage.setItem(
          "routerToPath",
          parsedDecodedFormData.routerToPath
        );
      }

      // IF CASO SEJA PRECISO FAZER REDIRECTS DA APLICAÇÃO REACT
      if (
        parsedDecodedFormData.reactApp &&
        parsedDecodedFormData.routerToPath
      ) {
        // IF PARA AS ROTAS
        this.getUserProfile(parsedDecodedFormData.routerToPath);
      } else {
        this.getUserProfile("/auth");
      }
    } catch (error) {
      this.$toast.error(error.message);
    }
  },
  methods: {
    async getUserProfile(routerToPath) {
      await this.apiService
        .getRequest(`user/user-profile`)
        .then((resp) => {
          const user = resp.content;
          this.$store.commit("SET_LOGGED_USER", JSON.stringify(user));

          this.$router.push(routerToPath);
        })
        .catch((err) => {
          this.$toast.error(this.$t("an_error_occurred"));
        });
    },
  },
};
</script>

<style scoped>
.center {
  min-height: 100vh;
  display: grid;
  place-items: center;
}

.page {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.page .loading {
  margin-bottom: 32px;
}

.page h2.title {
  font-weight: bold;
  font-size: 28px !important;
  text-align: center;
  margin-bottom: 16px;
}

.page p.sub-title {
  font-weight: normal;
  font-size: 16px !important;
  text-align: center;
}
</style>
